export const useLoginPopUp = defineStore('loginPopUp', () => {
    const visibility = ref(false)
   
    function open() {
      visibility.value = true
    }

    function close() {
        visibility.value = false
    }
  
    return { visibility, open, close }
  })